import { MantineProvider, Text, TextInput, Textarea, Box, Button, Checkbox, Switch, Radio, Group, SimpleGrid, Stepper, rem } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import { API } from '../../classes/API';
import { type } from 'os';
import { notifications } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import AppointmentForm from '../../components/AppointmentForm';
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  // useSearchParams,
  useLoaderData,
  useParams,
} from "react-router-dom";
import AdminNav from '../../components/AdminNav';

export default function Appointment() {

  const [cookies, setCookie] = useCookies(['token']);
  const [token, setToken] = useState("");
  const [showPage, setShowPage] = useState(false);
  const [familyData, setFamilyData] = useState<familyProps>();
  const params = useParams();




  useEffect(() => {
    //Runs only on the first render
    setToken(cookies.token)

    const cookieToken = cookies.token

    API.get('/reauth', cookieToken).then(
        (response) =>  {
            if (response.status === 401) {
                setCookie("token", "")
                window.location.replace('/admin/login');
                return <></>
            } else if (response.status === 200) {
          // Set the expiry date
          var date = new Date();
          var newDate = new Date(date.setMonth(date.getMonth() + 2));
          setCookie('token',response.body.token, {expires: newDate})
                setShowPage(true)
            }
        },
        error => {
            console.log('ERROR?!?',error)
        }
    );

    if (cookieToken === '') {
        window.location.replace('/admin/login');
        return //<></>
    }

    if (params["familyId"]) {
        // setIsNew(false)
        // setButtonText("Update User")
        API.get('/family/'+params["familyId"], cookieToken).then(
            (data) => {
                if (data.status == 200) {

                    setFamilyData(data.body)
                    /*

                    const userId = data.body.userId

                    if (data.body.appointment != null) {
                        const dateVal = data.body.appointment.dateOfAppointment
                        const dateTest = new Date(dateVal)
                        setDateObj(dateTest)

                        setAssignedUser(userId)
                        setDateObj(dateTest)
                        setDateValue(dateTest.getFullYear()+'-'+(dateTest.getMonth()+1)+'-'+dateTest.getDate()+' '+dateTest.getHours()+':'+dateTest.getMinutes())
                    }
                    // Load in the use data for assignment
                    if (userData.length == 0) {
                        API.get('/user',cookieToken).then((response) =>  {
                            if (response.status == 200) {
                                const userDataOptions:userSelectProps[] = []
                                const jsonData = response.body;
                                // userDataOptions.push({value:'0',label:'Please select'})
                                for(let i = 0; i < jsonData.length; i++) {
                                    let element = jsonData[i];
                                    const option:userSelectProps = {
                                        label:element.firstName + ' ' + element.lastName,
                                        value:`${element.id}`,
                                    }
                                    userDataOptions.push(option)
                                }
                                setUserData(userDataOptions);
                                setAssignedUser(`${userId}`)
                            }
                        });
                    }
                        */
                }
            }
        )
    }
  }, []);



  const pageContent = <Box maw={500} mx="auto"><AppointmentForm editable={false} data={familyData} /></Box>



  return (
    <AdminNav currentPage="Families" content={pageContent} />
  );
}
