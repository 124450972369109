import { useState } from 'react';
import { MantineProvider, Text, TextInput, Textarea, Box, Button, Checkbox, Switch, Radio, Group, SimpleGrid, Stepper, rem } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import { API } from '../classes/API';
import { type } from 'os';
import { notifications } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import AppointmentForm from '../components/AppointmentForm';

export default function Appointment() {



  return (
    <Box maw={500} mx="auto">
      <AppointmentForm editable={true} />
    </Box>
  );
}
