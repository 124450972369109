import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import Root from "./routes/root"
import Admin from "./routes/admin"
import UserList from "./routes/admin/user-list"
import UserForm from "./routes/admin/user-form"
import FamilyList from "./routes/admin/family-list"
import FamilyDetails from "./routes/admin/familyDetails"
import PumpList from "./routes/admin/pumpList"
import PumpDetails from "./routes/admin/pumpDetails"
import PumpLoanList from "./routes/admin/pump-loan-list"
import AppointmentList from "./routes/admin/appointment-list"
import EmailList from "./routes/admin/emailList"
import EmailForm from "./routes/admin/emailForm"
import Login from "./routes/login"
import Logout from "./routes/logout"
import Appointment from "./routes/appointment"
import AppointmentDev from "./routes/appointment-dev"
import AdminAppointment from "./routes/admin/appointment"
import PumpLoan from "./routes/pump-loan"
import AppointmentConfirmation from "./routes/appointment-confirm"
import PumpLoanConfirmation from "./routes/pump-loan-confirm"
import ConsultForm from "./routes/admin/consultForm"
import FollowUpForm from './routes/admin/followUpForm';
import GPForm from './routes/admin/gpForm';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
  },
  {
    path: "/admin/",
    element: <Admin/>,
  },
  {
    path: "/admin/login",
    element: <Login/>,
  },
  {
    path: "/admin/logout",
    element: <Logout/>,
  },
  {
    path: "/admin/consult",
    element: <ConsultForm/>,
  },
  {
    path: "/admin/users",
    element: <UserList/>,
  },
  {
    path: "/admin/users/add",
    element: <UserForm/>,
  },
  {
    path: "/admin/user/:userId",
    element: <UserForm/>,
  },
  {
    path: "/admin/families",
    element: <FamilyList/>,
  },
  {
    path: "/admin/families/user/:userId",
    element: <FamilyList/>,
  },
  {
    path: "/admin/family/:familyId",
    element: <FamilyDetails/>,
  },
  {
    path: "/admin/family/:familyId/appointment",
    element: <AdminAppointment/>,
  },
  {
    path: "/admin/family/:familyId/consult",
    element: <ConsultForm/>,
  },
  {
    path: "/admin/family/:familyId/follow-up",
    element: <FollowUpForm/>,
  },
  {
    path: "/admin/family/:familyId/gp",
    element: <GPForm/>,
  },
  {
    path: "/admin/pumps",
    element: <PumpList/>,
  },
  {
    path: "/admin/pumps/:pumpId",
    element: <PumpDetails/>,
  },
  {
    path: "/admin/appointments",
    element: <AppointmentList/>,
  },
  {
    path: "/admin/email",
    element: <EmailList/>,
  },
  {
    path: "/admin/email/:emailId",
    element: <EmailForm/>,
  },
  {
    path: "/appointment/",
    element: <Appointment/>,
  },
  {
    path: "/appointment-dev/",
    element: <AppointmentDev/>,
  },
  {
    path: "/pump-loan",
    element: <PumpLoan/>,
  },
  {
    path: "/pump-loan/confirmation",
    element: <PumpLoanConfirmation/>,
  },
  {
    path: "/appointment/confirmation",
    element: <AppointmentConfirmation/>,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <Notifications />
      <RouterProvider router={router} />
    </MantineProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
